import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Grid from "../../../../../components/grid"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Poem from "../../../../../components/poem"
import OrdnungTask from "../../../../../content/kurse/versteckte-ordnung/02-ordnung/task"
import PaperStack from "../../../../../components/paper-stack"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "versteckte-ordnung",
          chapterId: "02-ordnung",
          taskId: "ottos-mops",
        })
        navigate("/kurse/versteckte-ordnung/02-ordnung/ottos-mops/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Ernst Jandl: ottos mops" />
      <Stack>
        <Stack space={3}>
          <Heading as="span">Ernst Jandl</Heading>
          <Heading as="h1" level={2}>
            ottos mops
          </Heading>
        </Stack>
        <Grid columns={[1, 2]}>
          <PaperStack>
            <Poem size={[3, 3, 4]}>
              <p>ottos mops trotzt</p>
              <p>otto: fort mops fort</p>
              <p>ottos mops hopst fort</p>
              <p>otto: soso</p>
              <br />
              <p>otto holt koks</p>
              <p>otto holt obst</p>
              <p>otto horcht</p>
              <p>otto: mops mops</p>
              <p>otto hofft</p>
              <br />
              <p>ottos mops klopft</p>
              <p>otto: komm mops komm</p>
              <p>ottos mops kommt</p>
              <p>ottos mops kotzt</p>
              <p>otto: ogottogott</p>
            </Poem>
          </PaperStack>
          <OrdnungTask />
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
